import React from "react";
import { useApp } from "../../../../providers/AppContext";
import CreditCard from "../CreditCard/CreditCard";
import Pix from "../Pix/Pix";
import { PaymentMethodType } from "../../../../domain/enums/PaymentMethodType";

import "./PaymentStyle.scss";
import Invoice from "../Invoice/Invoice";

function Payment() {
	const {
		paymentMethods,
		paymentType,
		setPaymentType,
		order,
		setTotal,
		creditCardInstallments,
		setInstallmentInterest,
	} = useApp();
	const hasInstallments = creditCardInstallments.length > 0;

	const handlePaymentMethod = (e) => {
		let paymentType = Number(e.target.value);
		setPaymentType(paymentType);

		if (paymentType === PaymentMethodType.CreditCard && hasInstallments) {
			let oneInstallment = creditCardInstallments[0];
			setTotal(oneInstallment.totalAmount);
			if (oneInstallment.percentTaxRate !== 0)
				setInstallmentInterest(oneInstallment.totalAmount - order?.totalAmount);
			return;
		}

		setTotal(order.totalAmount - order.bonusAmount ?? 0);
		setInstallmentInterest(0);
	};

	const renderPaymentOptions = () => {
		return paymentMethods.map((payment) => {
			// If there's no credit card installments, CreditCard option won't be available to select
			if (payment.type === PaymentMethodType.CreditCard && !hasInstallments) return <></>;

			return (
				<option key={payment.type} value={payment.type}>
					{payment.info}
				</option>
			);
		});
	};

	const handlePaymentType = (paymentType) => {
		if (paymentType === PaymentMethodType.Pix) return "PAGAR COM PIX";

		if (paymentType === PaymentMethodType.Invoice) return "PAGAR COM BOLETO FATURADO";

		return "INFORME OS DADOS PARA O PAGAMENTO";
	};

	return (
		<div className="payment">
			{paymentMethods.length > 0 && (
				<>
					<p className="payment__title">Selecione o método de pagamento</p>
					<div className="form-control payment__form">
						<label htmlFor="exampleInputEmail1" className="form-label form-label__payment how__pay">
							Desejo pagar com:
						</label>
						<select
							className="form-select"
							aria-label="Forma de Pagamento"
							onChange={handlePaymentMethod}
							value={paymentType}
						>
							<option value={PaymentMethodType.None} disabled>
								Toque para selecionar o método de pagamento
							</option>
							{renderPaymentOptions()}
						</select>
					</div>
					{paymentType !== PaymentMethodType.None && (
						<p className="payment__title py-2">{handlePaymentType(PaymentMethodType)}</p>
					)}
					{paymentType === PaymentMethodType.CreditCard && <CreditCard />}
					{paymentType === PaymentMethodType.Pix && <Pix />}
					{paymentType === PaymentMethodType.Invoice && <Invoice />}
				</>
			)}
		</div>
	);
}

export default Payment;
