import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useApp } from '../../../providers/AppContext';
import './PaymentRefusedStyle.scss';
import refusedPayment from '../../../assets/icons/error_outline.png';
function PaymentRefused() {
	const { code, clientId } = useParams();
	const navigate = useNavigate();
	const { setRetry } = useApp();

	const retryPayment = () => {
		setRetry(true);
		navigate(`/${clientId}/${code}`);
	};

	return (
		<>
			<img className="png-icons__48px" src={refusedPayment} alt="Confirmado" />
			<span className="status__refused__title text-center">
				Pagamento recusado
			</span>
			<div className="status__refused__detail col-10 text-center">
				Houve um problema com seu pagamento e não foi possível finalizar o
				pedido. Toque abaixo para tentar novamente!
			</div>
			<div className="d-grid col-md-12 col-lg-6">
				<button
					onClick={() => retryPayment()}
					className="status__refused__button btn"
				>
					Tentar novamente!
				</button>
			</div>
		</>
	);
}

export default PaymentRefused;
