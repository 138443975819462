import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApp } from "../../../../providers/AppContext";

import arrow_forward from "../../../../assets/icons/arrow-left-1.png";
import info from "../../../../assets/icons/info.png";

function Invoice() {
	const { setPaymentMethod, paymentMethods, order, setRetry } = useApp();
	const { code, clientId } = useParams();
	const [loading, setLoading] = useState(false);
	const [errorResponse, setErrorResponse] = useState(false);
	const navigate = useNavigate();

	const requestInvoice = async () => {
		setLoading(true);
		let payment = paymentMethods.find((payment) => payment.type === 12);

		const paymentInfo = {
			paymentMethod: payment,
		};

		const paymentMethodResponse = await setPaymentMethod(code, paymentInfo);

		if (paymentMethodResponse.status === 200) {
			setLoading(false);
			setRetry(false);

			if (paymentMethodResponse?.data?.paymentMethod?.paymentStatus !== 13) {
				navigate(`/${clientId}/${code}/process`);
			}
		}

		setLoading(false);
		setErrorResponse(true);
	};

	return (
		<>
			<div className="row g-3 payment__form">
				<div className="col-12 d-grid gap-2 mx-auto mt-4">
					<button type="button" className="btn btn-dark" onClick={() => requestInvoice()}>
						<span className="d-flex align-items-center justify-content-center">
							{loading && (
								<span
									className="spinner-border spinner-border-sm mr-3"
									role="status"
									aria-hidden="true"
									style={{ marginRight: "0.5em" }}
								></span>
							)}
							<span>
								Pagar (
								{order?.totalAmount?.toLocaleString("pt-br", {
									style: "currency",
									currency: "BRL",
								})}
								)
							</span>
							<img className="png-icons__24px" src={arrow_forward} alt="" />
						</span>
					</button>
					{errorResponse && (
						<div className="alert alert-danger p-3 mb-0 mt-2" role="alert">
							<div className="d-flex align-items-center">
								<div className="flex-shrink-0">
									<img className="png-icons__24px" src={info} alt="" />
								</div>
								<div className="flex-grow-1 ms-3 d-flex align-items-center">
									<p className="mb-0">
										Atenção! Parece que houve um problema ao realizar a sua solicitação. Tente novamente!
									</p>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
}

export default Invoice;
