import React from 'react';
import { useApp } from '../../../providers/AppContext';
import './WaitingPaymentStyle.scss';
import confirmPayment from '../../../assets/icons/checkmark-circle.png';
function WaitingPayment() {
	const { order } = useApp();
	const { appStore, googlePlay } = useApp();
	return (
		<>
			<img className="png-icons__48px" src={confirmPayment} alt="Confirmado" />
			<span className="status__waiting__title text-center">
				Aguardando confirmação de pagamento!
			</span>
			<div className="status__waiting__detail col-10 text-center">
				Em breve você será notificado com informações atualizadas sobre o pedido
				pelo e-mail:{' '}
				<span className="status__waiting__detail__email">
					{order?.buyer?.email}
				</span>{' '}
				e pelo aplicativo
				<br /> <br />
			</div>
			{order?.retailer?.androidStoreUrl && order?.retailer?.androidStoreUrl && (
				<>
					<div className="status__waiting__detail col-10 text-center">
						Acompanhe atualizações dos seus pedidos através do nosso APP, baixe
						agora em:
					</div>
					<div className="status__confirmed__images d-flex">
						{order?.retailer?.androidStoreUrl && (
							<a href={order?.retailer?.androidStoreUrl}>
								<img src={googlePlay} alt="google play" />
							</a>
						)}
						{order?.retailer?.androidStoreUrl && (
							<a href={order?.retailer?.androidStoreUrl}>
								<img src={appStore} alt="app store" />
							</a>
						)}
					</div>
				</>
			)}
		</>
	);
}

export default WaitingPayment;
