import React from 'react';

import './loading.scss';

export default function Loading() {
	return (
		<div className="container neo-loading">
			<div className="loadingio-spinner-ripple-5l89yugl07g  ">
				<div className="ldio-wbg2kxit8hs">
					<div></div>
					<div></div>
				</div>
			</div>
		</div>
	);
}
