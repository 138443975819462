import React, { createContext, useContext, useState } from "react";
import { API } from "../API";
import appStore from "../assets/images/app-store.png";
import googlePlay from "../assets/images/google-play.png";
import neomode from "../assets/images/neomode.png";
import visaCard from "../assets/images/creditCardBrand/icon-visa-24px.svg";
import masterCard from "../assets/images/creditCardBrand/icon-master-24px.svg";
import americanExpress from "../assets/images/creditCardBrand/icon-americanexpress-24px.svg";
import defaultCreditCard from "../assets/images/creditCardBrand/credit_card.svg";
import { useNavigate } from "react-router-dom";
import { PaymentMethodType } from "../domain/enums/PaymentMethodType";
import { PaymentMethodTypeDescription } from "../domain/enums/PaymentMethodDescription";

export const AppProvider = createContext({});

export default function AppContext(props) {
	const [logo, setLogo] = useState("");
	const [products, setProducts] = useState([]);
	const [copied, setCopied] = useState(false);
	const [seller, setSeller] = useState({});
	const [order, setOrder] = useState({});
	const [creditCardInstallments, setCreditCardInstallments] = useState([]);
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [paymentType, setPaymentType] = useState(PaymentMethodType.None);
	const [pixCode, setPixCode] = useState("");
	const [status, setStatus] = useState(null);
	const [cpfCNPJ, setCpfCNPJ] = useState("");
	const [creditCard, setCreditCard] = useState("");
	const [creditBrand, setCreditBrand] = useState(defaultCreditCard);
	const [cvv, setCvv] = useState("");
	const [holder, setHolder] = useState("");
	const [expirationDate, setExpirationDate] = useState("");
	const [installment, setInstallment] = useState(1);
	const [retry, setRetry] = useState(false);
	const [total, setTotal] = useState(0);
	const [installmentInterest, setInstallmentInterest] = useState(0);
	const navigate = useNavigate();

	const getOrder = async (orderId) => {
		try {
			const response = await API.get(`management/commerce/orders/${orderId}/generic`);

			setProducts(response.data.items);
			setLogo(response.data.retailer.logoHorizontalPrimaryImageUrl);
			setSeller(response.data.seller);
			setPixCode(response.data.paymentMethod.paymentCode);
			setStatus(response.data.paymentMethod.paymentStatus);
			setOrder(response.data);
			let total = response.data.totalAmount - response.data.bonusAmount ?? 0;
			setTotal(total);
			return { status: response.status, data: response.data };
		} catch (error) {
			console.log(error);
			return error.response.status;
		}
	};

	const getPaymentMethods = async () => {
		try {
			const response = await API.get(`management/commerce/orders/paymentmethods`);

			setPaymentMethods(response.data);
			return;
		} catch (error) {
			console.log(error);
			return;
		}
	};

	const getInstallments = async (orderId) => {
		try {
			const response = await API.get(`management/commerce/orders/${orderId}/installments`);
			const creditCard = response.data.find(
				(installmentObj) => installmentObj.type === PaymentMethodTypeDescription.CreditCard
			);
			if (creditCard) setCreditCardInstallments(creditCard.installments);
			return;
		} catch (error) {
			console.log(error);
			return;
		}
	};

	const setPaymentMethod = async (orderId, data) => {
		try {
			const response = await API.put(`management/commerce/orders/${orderId}/payment`, data);
			setPixCode(response.data.paymentMethod.paymentCode);
			return { status: response.status, data: response.data };
		} catch (error) {
			console.log(error.response.status);
			return { status: error.response.status, data: {} };
		}
	};

	const changeRoute = (paymentStatus, orderId, clientId) => {
		if (paymentStatus !== 0 && paymentStatus !== 13) {
			navigate(`/${clientId}/${orderId}/process`);
		}
	};

	return (
		<AppProvider.Provider
			value={{
				logo,
				appStore,
				googlePlay,
				neomode,
				visaCard,
				masterCard,
				americanExpress,
				defaultCreditCard,
				copied,
				setCopied,
				getOrder,
				products,
				setProducts,
				getPaymentMethods,
				getInstallments,
				setPaymentMethod,
				seller,
				creditCardInstallments,
				paymentMethods,
				setPaymentMethods,
				paymentType,
				setPaymentType,
				pixCode,
				setPixCode,
				status,
				setStatus,
				order,
				setOrder,
				cpfCNPJ,
				setCpfCNPJ,
				creditCard,
				setCreditCard,
				creditBrand,
				setCreditBrand,
				cvv,
				setCvv,
				holder,
				setHolder,
				changeRoute,
				retry,
				setRetry,
				expirationDate,
				setExpirationDate,
				installment,
				setInstallment,
				total,
				setTotal,
				installmentInterest,
				setInstallmentInterest,
			}}
		>
			{props.children}
		</AppProvider.Provider>
	);
}

export const useApp = () => useContext(AppProvider);
