import React from 'react';
import './ProcessingPaymentStyles.scss';

function ProcessingPayment() {
	return (
		<>
			<i className="loader"></i>
			<span className="status__processing__title text-center">
				Processando Pagamento
			</span>
			<div className="status__processing__detail col-10 text-center">
				Aguarde! Estamos validando seu pagamento, isso pode levar alguns
				momentos
			</div>
		</>
	);
}

export default ProcessingPayment;
