import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import "./HomeStyle.scss";

import StoreAddress from "./components/StoreAddress/StoreAddress";
import PurchaseSummary from "./components/PurchaseSummary/PurchaseSummary";
import Values from "./components/Values/Values";
import Payment from "./components/Payment/Payment";
import { useApp } from "../../providers/AppContext";
import Loading from "../../components/Loading/Loading";
import arrow_forward from "../../assets/icons/arrow-left-1.png";
function Home() {
	const { getOrder, getPaymentMethods, getInstallments, paymentMethods, changeRoute, retry } = useApp();
	const { code, clientId } = useParams();
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		startAPIs();
	}, []);

	const startAPIs = async () => {
		const response = await getOrder(code);
		await getPaymentMethods();
		await getInstallments(code);
		if (!retry) {
			const cancellationStatus = [10, 11, 12];

			const isOrderStatusCanceled = cancellationStatus.includes(response?.data?.status);

			if (isOrderStatusCanceled) {
				navigate(`/${clientId}/${code}/process`);
				return;
			}

			await changeRoute(response?.data?.paymentMethod?.paymentStatus, code, clientId);
		}
		setLoading(false);
	};

	const showPayment = () => {
		let payment = document.getElementById("payment");
		let products = document.getElementById("products");
		let returnProduct = document.getElementById("returnProduct");
		payment.classList.add("payment--show");
		payment.classList.remove("payment--hidden");
		products.classList.add("payment--hidden");
		returnProduct.classList.add("payment--show");
	};

	return (
		<>
			{loading ? (
				<Loading />
			) : (
				<div className="row">
					<div id="products" className="col-lg-6 col-md-12">
						<div className=" col-12">
							<StoreAddress />
						</div>
						<div className=" col-12">
							<PurchaseSummary />
						</div>
						<div className=" col-12 mobile--show">
							<Values />
						</div>
						<div className="col-12 d-grid gap-2 mx-auto mt-4 mobile--show next__button">
							<button type="button" className="btn btn-dark" onClick={() => showPayment()}>
								<span className="d-flex align-items-center justify-content-center">
									Ir para o pagamento &nbsp;&nbsp;
									<img className="png-icons__24px" src={arrow_forward} alt="" />
								</span>
							</button>
						</div>
					</div>
					<div id="payment" className="col-lg-6 col-md-12 payment--hidden">
						<div className=" col-12 mobile--show">
							<StoreAddress />
						</div>
						<div className=" col-12 desktop--show">
							<Values />
						</div>
						<div className=" col-12 ">
							{paymentMethods.length > 0 ? (
								<Payment />
							) : (
								<div className="col-12">
									<Loading />
								</div>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default Home;
