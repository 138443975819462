import React from "react";
import { useApp } from "../../../../providers/AppContext";
import ContentLoader from "react-content-loader";

import "./PurchaseSummary.scss";

function PurchaseSummary() {
	const { products } = useApp();

	const renderLoading = () => {
		return (
			<div className="" style={{ overflow: "hidden", paddingBottom: "1em" }}>
				<ContentLoader height={100} backgroundColor="#e6e6e6" foregroundColor="#fafafa">
					<rect x="0" y="40" rx="5" ry="5" width="44" height="44" />
					<rect x="65" y="40" rx="5" ry="5" width="200" height="5" />
					<rect x="65" y="55" rx="5" ry="5" width="200" height="5" />
					<rect x="65" y="70" rx="5" ry="5" width="200" height="5" />
					<rect x="65" y="85" rx="5" ry="5" width="200" height="5" />
				</ContentLoader>
			</div>
		);
	};

	return (
		<div className="purchase__summary">
			<p className="purchase__summary__title">Resumo da sua compra</p>
			<ul className="list-group list-group-flush">
				<li className="list-group-item list-group-item__title">
					Itens do pedido ({products.length ? products.length : "..."})
				</li>
				<li className="list-group-item list-group-item__products">
					<ul className="list-group list-group-flush">
						{products.length < 1 ? (
							renderLoading()
						) : (
							<>
								{products?.map((product, index) => (
									<li className="list-group-item d-flex product" key={index}>
										<div className="flex-shrink-0">
											<img
												className="product__image"
												src={
													!product?.sku?.imageUrl ? "https://via.placeholder.com/40" : product.sku.imageUrl
												}
												alt=""
											/>
										</div>
										<div className="flex-grow-1 ms-3">
											<p className="product__title">{product.sku.product.name}</p>
											<p className="product__description">
												{product.sku.attributes.map((atribute) => {
													return `${atribute.title}: ${atribute.label ?? atribute.value} |`;
												})}{" "}
												Código: {product.sku.code}
											</p>
											<div className="d-flex justify-content-lg-between flex-md-column flex-lg-row flex-sm-column flex-column">
												<p className="product__description col-6">
													<strong>
														Quantidade: {product.quantity} | Preço unitário:
														{product?.price?.toLocaleString("pt-br", {
															style: "currency",
															currency: "BRL",
														})}
													</strong>
												</p>
												<p className="product__description col-6 text-lg-end total__value">
													<strong>
														Valor total:{" "}
														{product.totalPrice.toLocaleString("pt-br", {
															style: "currency",
															currency: "BRL",
														})}
													</strong>
												</p>
											</div>
										</div>
									</li>
								))}
							</>
						)}
					</ul>
				</li>
			</ul>
		</div>
	);
}

export default PurchaseSummary;
