import dayjs from 'dayjs';
import React from 'react';
import { useApp } from '../../../providers/AppContext';
import './PaymentConfirmedStyle.scss';
import confirmPayment from '../../../assets/icons/checkmark-circle.png';

function PaymentConfirmed() {
	const { order } = useApp();
	const { appStore, googlePlay } = useApp();

	return (
		<>
			<img className="png-icons__48px" src={confirmPayment} alt="Confirmado" />
			<span className="status__confirmed__title text-center">
				Pagamento Confirmado!
			</span>
			<span className="status__confirmed__date text-center">
				{
					dayjs(order?.paymentMethod?.lastUpdate).format('DD/MM/YYYY HH:mm:ss') // '25/01/2019'
				}
			</span>
			<div className="status__confirmed__detail col-10 text-center">
				Acompanhe as informações atualizadas do pedido pelo e-mail{' '}
				<span className="status__confirmed__detail__email">
					{order?.buyer?.email}
				</span>{' '}
				ou através do nosso aplicativo!
			</div>
			{order?.retailer?.androidStoreUrl && order?.retailer?.androidStoreUrl && (
				<>
					<div className="status__confirmed__detail col-10 text-center">
						<p>Baixe agora em:</p>
					</div>
					<div className="status__confirmed__images d-flex">
						{order?.retailer?.androidStoreUrl && (
							<a href={order?.retailer?.androidStoreUrl}>
								<img src={googlePlay} alt="google play" />
							</a>
						)}
						{order?.retailer?.androidStoreUrl && (
							<a href={order?.retailer?.androidStoreUrl}>
								<img src={appStore} alt="app store" />
							</a>
						)}
					</div>
				</>
			)}
		</>
	);
}

export default PaymentConfirmed;
