import React from "react";
import ContentLoader from "react-content-loader";

import { Outlet, useParams, useNavigate } from "react-router-dom";
import { useApp } from "../providers/AppContext";
import returnArrow from "../assets/icons/arrow-left.png";
import lock from "../assets/icons/lock.png";

import "./LayoutStyle.scss";

function Layout() {
	const navigate = useNavigate();
	const { logo, neomode } = useApp();
	const { code, clientId } = useParams();
	const { setRetry } = useApp();

	const retryPayment = () => {
		setRetry(true);
		navigate(`/${clientId}/${code}`);
	};

	const renderLoading = () => {
		return (
			<div className="" style={{ overflow: "hidden", paddingBottom: "1em" }}>
				<ContentLoader height={187} backgroundColor="#e6e6e6" foregroundColor="#fafafa">
					<rect x="0" y="0" rx="5" ry="5" width="187" height="72" />
				</ContentLoader>
			</div>
		);
	};
	return (
		<div className="container">
			{!logo ? (
				<div className="logo__external">{renderLoading()}</div>
			) : (
				<img className="logo__external" src={logo} alt="Logotipo Loja" />
			)}
			<div className="card mt-3">
				<div className="card-header d-flex justify-content-center mobile__button--show">
					<button
						id="returnProduct"
						type="button"
						className="btn btn-link align-self-center return-button payment--hidden"
						onClick={() => retryPayment()}
					>
						<img className="png-icons__24px" src={returnArrow} alt="Voltar" />
					</button>
					{!logo ? (
						<div className="logo__internal">{renderLoading()}</div>
					) : (
						<img
							className="logo__internal"
							src={logo}
							alt="Logotipo Loja"
							style={{ justifySelf: "center" }}
						/>
					)}
				</div>
				<div className="card-body">
					<Outlet />
				</div>
			</div>
			<div className="page__footer d-flex flex-column flex-lg-row justify-content-between align-items-center">
				<div className="page__footer__left d-flex align-items-center">
					<img className="png-icons__20px page__footer__left__icon" src={lock} alt="" />
					<span className="page__footer__left__text">AMBIENTE SEGURO</span>
				</div>
				<div className="page_footer_right d-flex align-items-center">
					<span className="page__footer__right__text">powered by</span>
					<img className="page__footer__right__img" src={neomode} alt="neomode logo" />
				</div>
			</div>
		</div>
	);
}

export default Layout;
