import React, { useRef, useState } from 'react';
import { useApp } from '../../../../../../providers/AppContext';
import copy from '../../../../../../assets/icons/copy.png';
import done from '../../../../../../assets/icons/check.png';
import grid_view from '../../../../../../assets/icons/grid.png';
import check_circle_outline from '../../../../../../assets/icons/checkmark-circle (2).png';
import pix from '../../../../../../assets/icons/icon-pix.png';

function PayWithPix() {
	const { copied, setCopied, pixCode } = useApp();
	const codeRef = useRef(null);

	const copyCode = () => {
		codeRef.current.select();
		document.execCommand('copy');
		setCopied(true);
	};
	return (
		<>
			<div className="col-12">
				<div className="d-flex align-items-center">
					<div className="flex-shrink-0">
						<img className="png-icons__24px png-icons__mb1" src={copy} alt="" />
					</div>
					<div className="flex-grow-1 ms-3">
						<label
							for="exampleInputEmail1"
							className="form-label form-label__payment"
						>
							1. Copie o código abaixo
						</label>
						<input
							type="text"
							className="form-control"
							placeholder="codigopix"
							aria-label="First name"
							ref={codeRef}
							value={pixCode}
						/>
					</div>
				</div>
				<span className="col-12 d-grid gap-2 mx-auto mt-3">
					<button
						type="button"
						className={
							copied
								? 'btn btn-outline-success btn-sm active'
								: 'btn btn-outline-secondary btn-sm'
						}
						onClick={() => copyCode()}
					>
						{copied ? (
							<img className="png-icons__24px" src={done} alt="" />
						) : (
							<img className="png-icons__24px" src={copy} alt="" />
						)}

						{copied ? 'Código copiado' : 'Copiar código'}
					</button>
				</span>
			</div>
			<div className="col-12">
				<div className="d-flex align-items-center">
					<div className="flex-shrink-0">
						<img className="png-icons__24px png-icons__mb1" src={pix} alt="" />
					</div>
					<div className="flex-grow-1 ms-3">
						<p>
							2. Abra o aplicativo do seu banco, central de pagamentos ou
							instituição financeira e selecione o ambiente PIX
						</p>
					</div>
				</div>
			</div>
			<div className="col-12">
				<div className="d-flex align-items-center">
					<div className="flex-shrink-0">
						<img
							className="png-icons__24px png-icons__mb1"
							src={grid_view}
							alt=""
						/>
					</div>
					<div className="flex-grow-1 ms-3">
						<p>3. No ambiente PIX, toque na opção "Pix Copia e Cola"</p>
					</div>
				</div>
			</div>
			<div className="col-12">
				<div className="d-flex align-items-center">
					<div className="flex-shrink-0">
						<img
							className="png-icons__24px png-icons__mb1"
							src={check_circle_outline}
							alt=""
						/>
					</div>
					<div className="flex-grow-1 ms-3">
						<p>4. Agora é só realizar o pagamento e aguardar a confirmação</p>
					</div>
				</div>
			</div>
		</>
	);
}

export default PayWithPix;
