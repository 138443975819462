import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useApp } from "../../../../providers/AppContext";

import arrow_forward from "../../../../assets/icons/arrow-left-1.png";
import info from "../../../../assets/icons/info.png";

function CreditCard() {
	const {
		visaCard,
		masterCard,
		americanExpress,
		defaultCreditCard,
		creditCardInstallments,
		setPaymentMethod,
		paymentMethods,
		order,
		cpfCNPJ,
		setCpfCNPJ,
		creditCard,
		setCreditCard,
		creditBrand,
		setCreditBrand,
		cvv,
		setCvv,
		holder,
		setHolder,
		setRetry,
		expirationDate,
		setExpirationDate,
		installment,
		setInstallment,
		total,
		setTotal,
		setInstallmentInterest,
	} = useApp();

	const { code, clientId } = useParams();
	const navigate = useNavigate();
	const [validateDoc, setValidateDoc] = useState(true);
	const [validatecreditCard, setValidatecreditCard] = useState(true);
	const [errorResponse, setErrorResponse] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setCreditCard("");
		setExpirationDate("");
		setCvv("");
		setHolder("");
		setCpfCNPJ("");

		if (creditCardInstallments.length > 0) setInstallment(creditCardInstallments[0].installments);
	}, []);

	useEffect(() => {
		(creditCard.length === 0 ||
			(creditBrand === americanExpress && creditCard.length === 17) ||
			(creditBrand !== americanExpress && creditCard.length === 19)) &&
		checkLuhn(creditCard)
			? setValidatecreditCard(true)
			: setValidatecreditCard(false);
	}, [creditCard]);

	const CpfCNPJValidator = (e) => {
		//Remove tudo o que não é dígito
		e = e.replace(/\D/g, "");
		if (e.length <= 11) {
			//CPF
			e = e.replace(/(\d{3})(\d)/, "$1.$2");
			e = e.replace(/(\d{3})(\d)/, "$1.$2");
			e = e.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
		} else {
			//CNPJ
			e = e.replace(/^(\d{2})(\d)/, "$1.$2");
			e = e.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
			e = e.replace(/(\d{2})\.(\d{3})\.(\d{3})(\d)/, "$1.$2.$3/$4");
			e = e.replace(/(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})(\d)/, "$1.$2.$3/$4-$5");
		}

		let cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
		let cnpjRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;

		let isCPFValid = cpfRegex.test(e);
		let isCNPJValid = cnpjRegex.test(e);

		setValidateDoc(isCNPJValid || isCPFValid);
		setCpfCNPJ(e);
	};

	const creditCardValidator = (e) => {
		e = e.replace(/\D/g, ""); // Permite apenas dígitos
		if (e.length <= 4) {
			setCreditCard(e);
		} else {
			brandValidator(e);
			// American Express, 15 digitos (4-6-5)
			if (e.length < 17 && /^3[47]\d{0,13}$/.test(e)) {
				e = e.replace(/(\d{4})/, "$1 "); // primeira parte da mascara, todos os cartões é 4
				e = e.replace(/(\d{6})(\d)/, "$1 $2"); // segunda(6 digitos) e terceira (serão 5)
				setCreditCard(e);
			} else if (/^(?!37|34)\d{0,16}$/.test(e)) {
				// cartão de crédito regular, 16 digitos (4-4-4-4)
				e = e.replace(/(\d{4})/, "$1 "); // primeiros 4 digitos
				e = e.replace(/(\d{4}) (\d{4})/, "$1 $2 "); // faz o espaçamento entre os blocos de digitos
				e = e.replace(/(\d{4}) (\d{4}) (\d{4})/, "$1 $2 $3 "); // para o ultimo bloco (foi necessária várias linhas para formatar enquanto escreve)
				e = e.replace(/ $/, ""); // Remove o espaço se estiver sobrando (ao apagar digitos)
				setCreditCard(e);
			}
		}
	};

	const brandValidator = (digits) => {
		/^3[47][0-9]\d{0,13}$/.test(digits) // Valida se é American Express e troca o estado da logo
			? setCreditBrand(americanExpress)
			: /^5[1-5][0-9]\d{0,14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]\d{0,12}$/.test(
					digits
			  ) // Valida se é MasterCard e troca o estado da logo
			? setCreditBrand(masterCard)
			: /^4[0-9]\d{0,15}?$/.test(digits) // Valida se é Visa e troca o estado da logo
			? setCreditBrand(visaCard)
			: setCreditBrand(defaultCreditCard); // Se não entrar em nenhuma validação acima troca o estado da logo para padrão
	};

	const checkLuhn = (cardNum) => {
		// O algoritmo Luhn verifica se o número da conta do cartão é válido utilizando este calculo
		// Iniciando pelo dígito mais à direita do número do cartão, dobra os dígitos de índice par, exemplo:
		// cartão "4012 8888 8888 1881", os números substituídos por x dobram: "x0x2 x8x8 x8x8 x8x1"
		// Se o dígito dobrado for maior que 9 (ex. 8 * 2 = 16), subtrai 9 (16–9 = 7). (ou soma os digitos, 16 (1+6 = 7))
		// Soma todos os dígitos e divide por 10 (soma % 10 === 0), se não tiver "resto" o cartão é válido.
		cardNum = cardNum.replace(/\D/g, "");
		let sum = 0;
		let shouldDouble = false;
		// loop pelos digitos, iniciando pelo lado direito (final)
		for (let i = cardNum.length - 1; i >= 0; i--) {
			var digit = parseInt(cardNum.charAt(i));

			if (shouldDouble) {
				if ((digit *= 2) > 9) digit -= 9;
			}

			sum += digit;
			shouldDouble = !shouldDouble;
		}
		return sum % 10 === 0;
	};

	const cvvValidator = (e) => {
		e = e.replace(/\D/g, ""); // Permite apenas dígitos
		e = e.substring(0, 4); // Limita o tamanho
		setCvv(e);
	};

	const expirationDateValidator = (e) => {
		e = e.replace(/\D/g, ""); // Permite apenas números
		e === "" && setExpirationDate(e); // Para poder apagar o primeiro digito
		e.length <= 1 && /^0|1$/.test(e) && setExpirationDate(e); // para inserir o primeiro digito (0 ou 1)
		e.length === 2 && /^0[1-9]|1[0-2]$/.test(e) && setExpirationDate(e); // para o segundo digito, de acordo com o primeiro
		// O if é para não limitar os valores para os 2 ultimos digitos e inserir a divisória "/"
		if (e.length <= 4 && /^(0[1-9]|1[0-2])\d{0,2}$/.test(e)) {
			e = e.replace(/(\d{2})/, "$1/"); // Ao ter os dois digitos do mês adiciona "/"
			e = e.replace(/\/$/, ""); // Para poder remover a "/" se estiver sobrando no final (ao apagar terceiro digito)
			setExpirationDate(e);
		}
	};

	const sendCreditCard = async () => {
		setLoading(true);
		let payment = paymentMethods.find((payment) => payment.type === 1);

		payment.number = creditCard.replaceAll(" ", "");
		payment.expiration = expirationDate;
		payment.cvvEncrypted = cvv;
		payment.cardHolder = holder;
		payment.instalment = parseInt(installment);

		const paymentInfo = {
			paymentMethod: payment,
			quantityInstallments: parseInt(installment),
		};

		const paymentMethodResponse = await setPaymentMethod(code, paymentInfo);
		if (paymentMethodResponse.status === 200) {
			setLoading(false);
			setRetry(false);
			if (
				paymentMethodResponse?.data?.paymentMethod?.paymentStatus !== 0 &&
				paymentMethodResponse?.data?.paymentMethod?.paymentStatus !== 13
			) {
				navigate(`/${clientId}/${code}/process`);
				return;
			}
			return;
		}
		setLoading(false);
		setErrorResponse(true);
	};

	const handleSelectInstallment = (e) => {
		let installmentQuantity = e.target.value;
		let currentInstallment = creditCardInstallments.find(
			(installment) => installment.installments === Number(installmentQuantity)
		);
		let installmentTotalAmount = currentInstallment.totalAmount;
		setTotal(installmentTotalAmount);
		setInstallment(installmentQuantity);

		if (currentInstallment.percentTaxRate !== 0) {
			let installmentInterest = installmentTotalAmount - order?.totalAmount;
			setInstallmentInterest(installmentInterest);
			return;
		}

		setInstallmentInterest(0);
	};

	return (
		<div className="row g-3 payment__form">
			<div className="col-12">
				<label htmlFor="exampleInputEmail1" className="form-label form-label__payment">
					Número do Cartão
				</label>
				<input
					type="text"
					className={`credit__card__input ${
						!validatecreditCard ? "form-control is-invalid" : "form-control"
					}`}
					placeholder="Toque para preencher"
					aria-label="First name"
					value={creditCard}
					onChange={(e) => creditCardValidator(e.target.value)}
					style={{ backgroundImage: `url(${creditBrand})` }}
				/>
				<div className="invalid-feedback">Cartão de crédito inválido</div>
			</div>
			<div className="col-6">
				<label htmlFor="exampleInputEmail1" className="form-label form-label__payment">
					Validade
				</label>
				<input
					type="text"
					className="form-control"
					placeholder="Toque para preencher"
					aria-label="First name"
					value={expirationDate}
					onChange={(e) => expirationDateValidator(e.target.value)}
				/>
			</div>
			<div className="col-6">
				<label htmlFor="exampleInputEmail1" className="form-label form-label__payment">
					CVV
				</label>
				<input
					type="text"
					className="form-control"
					placeholder="Toque para preencher"
					aria-label="First name"
					value={cvv}
					onChange={(e) => cvvValidator(e.target.value)}
				/>
			</div>
			<div className="col-12">
				<label htmlFor="exampleInputEmail1" className="form-label form-label__payment">
					Nome do titular do cartão
				</label>
				<input
					type="text"
					value={holder}
					className="form-control"
					placeholder="Toque para preencher"
					aria-label="Last name"
					onChange={(e) => setHolder(e.target.value)}
				/>
			</div>
			<div className="col-lg-6 col-md-12">
				<label htmlFor="exampleInputEmail1" className="form-label form-label__payment">
					CPF/CNPJ do Titular
				</label>
				<input
					type="text"
					className={!validateDoc ? "form-control is-invalid" : "form-control"}
					placeholder="Toque para preencher"
					aria-label="Last name"
					onChange={(e) => CpfCNPJValidator(e.target.value)}
					value={cpfCNPJ}
					maxLength={18}
				/>
				{!validateDoc && (
					<div id="validationServerUsernameFeedback" className="invalid-feedback">
						Número de CPF/CNPJ inválido.
					</div>
				)}
			</div>
			<div className="col-lg-6 col-md-12">
				<label htmlFor="exampleInputEmail1" className="form-label form-label__payment">
					Número de Parcelas
				</label>
				<select
					className="form-select"
					aria-label="Default select example"
					onChange={handleSelectInstallment}
					value={installment}
				>
					{creditCardInstallments.map((installment) => (
						<option key={installment.installments} value={installment.installments}>
							{`Em ${installment.installments}x de`}{" "}
							{installment.percentTaxRate === 0
								? `${installment.amount.toLocaleString("pt-br", {
										style: "currency",
										currency: "BRL",
								  })} sem juros `
								: `${installment.amount.toLocaleString("pt-br", {
										style: "currency",
										currency: "BRL",
								  })} com juros`}{" "}
						</option>
					))}
				</select>
			</div>
			<div className="col-12 d-grid gap-2 mx-auto mt-4">
				<button
					type="button"
					className="btn btn-dark"
					onClick={() => sendCreditCard()}
					disabled={
						(!validatecreditCard && !validateDoc) ||
						!creditCard ||
						!cpfCNPJ ||
						!holder ||
						!cvv ||
						!expirationDate ||
						loading
					}
				>
					<div className="d-flex align-items-center justify-content-center">
						{loading && (
							<span
								className="spinner-border spinner-border-sm mr-3"
								aria-hidden="true"
								style={{ marginRight: "0.5em" }}
							/>
						)}
						<output>
							Pagar (
							{total?.toLocaleString("pt-br", {
								style: "currency",
								currency: "BRL",
							})}
							)
						</output>

						<img className="png-icons__24px" src={arrow_forward} alt="" />
					</div>
				</button>
				{errorResponse && (
					<div className="alert alert-danger p-3 mb-0 mt-2" role="alert">
						<div className="d-flex align-items-center">
							<div className="flex-shrink-0">
								<img className="png-icons__24px" src={info} alt="" />
							</div>
							<div className="flex-grow-1 ms-3 d-flex align-items-center">
								<p className="mb-0">
									Atenção! Parece que houve um problema ao realizar a sua solicitação. Tente novamente!
								</p>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default CreditCard;
