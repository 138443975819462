import React from "react"
import { useApp } from "../../../../providers/AppContext"

import "./ValueStyle.scss"

function Values() {
	const { order, total, installmentInterest } = useApp()

	return (
		<>
			<ul className="list-group list-group-flush values">
				<li className="list-group-item d-flex justify-content-between">
					<span>Subtotal</span>
					<span className="values__value">
						{order?.subTotalAmount?.toLocaleString("pt-br", {
							style: "currency",
							currency: "BRL",
						})}
					</span>
				</li>
				<li className="list-group-item d-flex justify-content-between">
					<span>Frete</span>
					<span className="values__value">
						{order?.freightAmount?.toLocaleString("pt-br", {
							style: "currency",
							currency: "BRL",
						})}
					</span>
				</li>
				{order?.discountAmount > 0 && (
					<li className="list-group-item d-flex justify-content-between">
						<span>Descontos</span>
						<span className="values__discount__value">
							-
							{order?.discountAmount?.toLocaleString("pt-br", {
								style: "currency",
								currency: "BRL",
							})}
						</span>
					</li>
				)}
				{order?.bonusAmount > 0 && (
					<li className="list-group-item d-flex justify-content-between">
						<span>Cashback</span>
						<span className="values__discount__value">
							-
							{order?.bonusAmount?.toLocaleString("pt-br", {
								style: "currency",
								currency: "BRL",
							})}
						</span>
					</li>
				)}
				{installmentInterest > 0 && (
					<li className="list-group-item d-flex justify-content-between">
						<span>Acréscimo</span>
						<span className="values__value">
							{installmentInterest.toLocaleString("pt-br", {
								style: "currency",
								currency: "BRL",
							})}
						</span>
					</li>
				)}
				<li className="list-group-item d-flex justify-content-between">
					<span className="values__total__text">Valor Total</span>
					<span className="values__total__value">
						{total?.toLocaleString("pt-br", {
							style: "currency",
							currency: "BRL",
						})}
					</span>
				</li>
			</ul>
		</>
	)
}

export default Values
