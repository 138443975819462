import React from 'react';

import './App.scss';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './layout/Layout';
import Home from './pages/Home/Home';
import PaymentProcessing from './pages/PaymentProcessing/PaymentProcessing.jsx';
import AppContext from './providers/AppContext';
import DocumentMeta from 'react-document-meta';

function App(props) {
	return (
		<>
			<Router>
				<AppContext>
					<Routes>
						<Route path="/" element={<Layout />}>
							<Route path="/:clientId/:code" element={<Home />}></Route>
							<Route
								path="/:clientId/:code/process"
								element={<PaymentProcessing />}
							></Route>
						</Route>
					</Routes>
				</AppContext>
			</Router>
		</>
	);
}

export default App;
