import React from 'react';
import ContentLoader from 'react-content-loader';
import { useApp } from '../../../../providers/AppContext';

import './StoreAddressStyle.scss';

function StoreAddress() {
	const { seller } = useApp();

	const renderLoading = () => {
		return (
			<>
				<div className="" style={{ overflow: 'hidden' }}>
					<ContentLoader
						height={80}
						backgroundColor="#e6e6e6"
						foregroundColor="#fafafa"
					>
						<rect x="0" y="0" rx="5" ry="5" width="200" height="5" />
						<rect x="0" y="20" rx="5" ry="5" width="200" height="5" />
						<rect x="0" y="40" rx="5" ry="5" width="200" height="5" />
						<rect x="0" y="60" rx="5" ry="5" width="200" height="5" />
					</ContentLoader>
				</div>
			</>
		);
	};
	return (
		<>
			<div className="address">
				{!seller ? (
					renderLoading()
				) : (
					<>
						<p>Pedido para</p>
						<p className="address__store">
							{seller.companyName ? seller.companyName : '***********'}
						</p>
						<p>{seller.fullAddress}</p>
						<p>{seller.cep}</p>
					</>
				)}
			</div>
		</>
	);
}

export default StoreAddress;
