import axios from "axios";

const API = axios.create({
	baseURL: process.env.REACT_APP_API_ENDPOINT,
	responseType: "json",
});

API.interceptors.request.use(async function (config) {
	if (!sessionStorage.getItem("access_token")) {
		await doAnonymousAuth(sessionStorage.getItem("client_id"));
	}
	config.headers.Authorization = `Bearer ${sessionStorage.getItem("access_token")}`;

	return config;
});

API.interceptors.response.use(
	(response) => response,
	async (error) => {
		const { status } = error.response;
		if (status === 401) {
			await doAnonymousAuth(sessionStorage.getItem("client_id"));
			window.location.reload();
		}
	}
);

async function doAnonymousAuth(clientId) {
	return new Promise((resolve, reject) => {
		if (!clientId) {
			clientId = window.location.pathname.split("/")[1];
			sessionStorage.setItem("client_id", clientId);
		}

		const authPath = "auth/v2/login";
		const parameters = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
		};
		const body = {
			client_id: clientId,
			client_secret: process.env.REACT_APP_CLIENT_SECRET,
			scope: process.env.REACT_APP_SCOPE,
			grant_type: process.env.REACT_APP_GRANT_TYPE,
		};
		axios
			.post(process.env.REACT_APP_API_RAW_ENDPOINT + authPath, body, parameters)
			.then(async (res) => {
				if (!res.data.access_token) throw new Error("Unavailable access_token property");
				sessionStorage.setItem("access_token", res.data.access_token);
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export { API };
