import React from "react";

import QRCode from "qrcode.react";
import { useApp } from "../../../../../../providers/AppContext";
import pix from "../../../../../../assets/icons/icon-pix.png";
import grid from "../../../../../../assets/icons/grid.png";
import photo_camera from "../../../../../../assets/icons/camera_alt.png";
function PayWithPixCode() {
	const { pixCode } = useApp();
	return (
		<div className="row">
			<div className="col-4">{pixCode !== null && <QRCode value={pixCode} size="140" />}</div>
			<div className="col-8 ">
				<div className="col-12">
					<div className="d-flex">
						<div className="flex-shrink-0">
							<img className="png-icons__24px png-icons__mb1" src={pix} alt="" />
						</div>
						<div className="flex-grow-1 ms-3">
							<p>
								2. Abra o aplicativo do seu banco, central de pagamentos ou instituição financeira e selecione
								o ambiente PIX
							</p>
						</div>
					</div>
				</div>
				<div className="col-12">
					<div className="d-flex">
						<div className="flex-shrink-0">
							<img className="png-icons__24px png-icons__mb1" src={grid} alt="" />{" "}
						</div>
						<div className="flex-grow-1 ms-3">
							<p>3. No ambiente PIX, toque na opção "Pix Copia e Cola"</p>
						</div>
					</div>
				</div>
				<div className="col-12">
					<div className="d-flex">
						<div className="flex-shrink-0">
							<img className="png-icons__24px png-icons__mb1" src={photo_camera} alt="" />
						</div>
						<div className="flex-grow-1 ms-3">
							<p>4. Agora é só realizar o pagamento e aguardar a confirmação</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PayWithPixCode;
