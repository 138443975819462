import React from "react";
import "./PaymentCanceledStyle.scss";
import linkOff from "../../../assets/icons/link_off.svg";

function PaymentCanceled() {
	return (
		<div className="status__canceled ">
			<img className="status__canceled__icons" src={linkOff} alt="Cancelado" />
			<span className="status__canceled__title text-center">
				O link de pagamento não está mais disponível
			</span>
			<div className="status__canceled__detail col-10 text-center">
				O link de pagamento que você está tentando acessar não está mais disponível, entre em contato com a
				loja e solicite um novo link.
			</div>
		</div>
	);
}

export default PaymentCanceled;
