export const PaymentMethodTypeDescription = {
	None: "None",
	CreditCard: "CreditCard",
	PickupStore: "PickupStore",
	BankSlip: "BankSlip",
	Voucher: "Voucher",
	DebitCard: "DebitCard",
	Pix: "Pix",
	PaymentLink: "PaymentLink",
	InstallmentPix: "InstallmentPix",
	Cashback: "Cashback",
	TerminalCreditCard: "TerminalCreditCard",
	TerminalDebitCard: "TerminalDebitCard",
	Invoice: "Invoice",
};
