import React, { useEffect, useState } from "react";
import WaitingPayment from "./WaitingPayment/WaitingPayment";
import ProcessingPayment from "./ProcessingPayment/ProcessingPayment";
import PaymentConfirmed from "./PaymentConfirmed/PaymentConfirmed";
import PaymentRefused from "./PaymentRefused/PaymentRefused";
import { useApp } from "../../providers/AppContext";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import { PaymentMethodType } from "../../domain/enums/PaymentMethodType";
import PaymentCanceled from "./PaymentCanceled/PaymentCanceled";

function PaymentProcessing() {
	const { getOrder, status, order } = useApp();
	const { code } = useParams();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		mountStatus();
	}, []);

	const mountStatus = async () => {
		await getOrder(code);
		setLoading(false);
	};

	return (
		<>
			{loading ? (
				<Loading />
			) : (
				<div className="row">
					<div id="processStatus" className="col-lg-12">
						<div className="col-12 d-flex flex-column align-items-center">
							{status === 0 && order?.paymentMethod?.type === PaymentMethodType.Invoice && (
								<PaymentConfirmed />
							)}
							{(status === 1 || status === 2 || status === 3 || status === 4) && <ProcessingPayment />}
							{status === 5 && <WaitingPayment />}
							{status === 6 && <PaymentConfirmed />}
							{order.status === 10 || order.status === 11 || order.status === 12 ? (
								<PaymentCanceled />
							) : (
								(status === 7 || status === 8 || status === 9 || status === 10 || status === 11) && (
									<PaymentRefused />
								)
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default PaymentProcessing;
